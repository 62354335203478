body {
  margin: 0;
  height: 100vh;
}

* {
  box-sizing: border-box !important;
}

#root {
  height: 100%;
  overflow-y: hidden;
}


/* Scroll width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.large-grey-scrollbar .MuiAutocomplete-listbox::-webkit-scrollbar,
.large-grey-scrollbar-editor::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.large-grey-scrollbar .MuiAutocomplete-listbox::-webkit-scrollbar-track,
.large-grey-scrollbar-editor::-webkit-scrollbar-track {
  background: #f0f4f5 !important;
}

.large-grey-scrollbar .MuiAutocomplete-listbox::-webkit-scrollbar-thumb,
.large-grey-scrollbar-editor::-webkit-scrollbar-thumb {
  background: #dee2e6 !important;
  border-radius: 15px;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Scroll track */
::-webkit-scrollbar-track {
  background: #eaecef;
}

/* Scroll handle */
::-webkit-scrollbar-thumb {
  background: #cfd4d9;
  border-radius: 10px;
}

/* Scroll handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #878e95;
}

/* Snackbar */
.SnackbarItem-contentRoot {
  background-color: #313131 !important;
}

.SnackbarItem-variantInfo {
  background: #00bcd4 !important;
}

.SnackbarItem-variantInfo>.SnackbarItem-message {
  width: 100%;
}

/* RPV */
.rpv-search__highlight {
  background-color: #00bcd4 !important;
  opacity: 0.3;
}

.rpv-highlight__selected-text {
  background-color: #00bcd4 !important;
  opacity: 0.3;
}

.file-preview-text-only .rpv-core__page-layer {
  box-shadow: none;
  margin: 0;
}

.file-preview-text-only .rpv-core__inner-page {
  padding: 0;
}

/* MUI x-date-picker */
.MuiDateCalendar-root {
  height: fit-content !important;
}
