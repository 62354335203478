[itemref="feedback"]:hover {
  background-color: #00838f !important;
  color: #ececec !important;
}

:root {
  --amplify-primary-color: #00bcd4;
  --amplify-primary-tint: #00838f;
  --amplify-primary-shade: #00838f;
  --amplify-primary-contrast: #00838f;
  --amplify-components-field-label-color: #000;
  --amplify-components-authenticator-router-border-style: none;
  --amplify-components-authenticator-router-box-shadow: var(--amplify-shadows-large);
}

[data-amplify-authenticator] {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-radius: 15px;
}

[data-amplify-authenticator] .amplify-label {
  font-size: 18px;
  font-weight: 600;
}

[data-amplify-authenticator] .amplify-input {
  border-radius: 20px;
  border: var(--amplify-components-fieldcontrol-border-width) var(--amplify-components-fieldcontrol-border-style) var(--amplify-components-fieldcontrol-border-color) !important;
}

[data-amplify-authenticator] .amplify-input[name="password"] {
  border-right: none;
}

[data-amplify-authenticator] .amplify-button.amplify-field__show-password {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

[data-amplify-authenticator-signin] .amplify-button[type="submit"] {
  width: 150px;
  border-radius: 20px;
  display: flex;
  align-self: center;
  margin-top: 20px;
}
