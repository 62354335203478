/* UPPY overrides */
.uppy-Dashboard-AddFiles-title {
  color: #696e70;
}

.uppy-Dashboard-browse {
  color: #00bcd4;
}

.uppy-Dashboard-browse:hover {
  border-bottom: 1px solid #00bcd4;
}

.uppy-DashboardTab-btn {
  width: 105px !important;
}

.uppy-DashboardTab-btn svg {
  width: 60px !important;
  height: 60px !important;
}

.uppy-DashboardTab-btn .uppy-DashboardTab-name {
  font-size: 16px;
  color: #696e70;
}

/*removes the 'My device' icon from modal*/
.uppy-DashboardTab[data-uppy-acquirer-id="MyDevice"] {
  display: none;
}

.uppy-DashboardTab[data-uppy-acquirer-id="GoogleDrive"],
.uppy-DashboardTab[data-uppy-acquirer-id="Dropbox"],
.uppy-DashboardTab[data-uppy-acquirer-id="Box"],
.uppy-DashboardTab[data-uppy-acquirer-id="OneDrive"] {
  opacity: 0.5;
  pointer-events: none;
}

.uppy-Dashboard-progressindicators .uppy-StatusBar.is-error svg.uppy-StatusBar-statusIndicator {
  display: none;
}

.uppy-Dashboard-progressindicators .uppy-StatusBar.is-error .uppy-StatusBar-status {
  flex-direction: column-reverse;
}

.uppy-Dashboard-progressindicators .uppy-StatusBar.is-error .uppy-StatusBar-statusSecondary {
  font-size: 13px;
  color: #00838f;
}