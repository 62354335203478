/* https://github.com/codex-team/editor.js/issues/1328 */
.ce-custom-full-width .ce-block__content {
  max-width: 100% !important;
  margin-left: 30px !important;
}

.ce-custom-full-width .ce-toolbar__content {
  max-width: 100% !important;
  margin-left: 30px !important;
}

.ce-custom-full-width .cdx-block {
  max-width: 100% !important;
}

.codex-editor--narrow .ce-toolbar__actions {
  right: unset !important;
  left: -60px !important;
}

.codex-editor--narrow .ce-toolbox .ce-popover {
  right: -150px !important;
}

.codex-editor--narrow .ce-block--focused {
  margin-right: unset !important;
  padding-right: unset !important;
}

.ce-block a {
  cursor: pointer;
  text-decoration: none;
  color: #048290 !important;
}

.ce-block--selected .ce-block__content {
  background: unset;
}

a:hover {
  text-decoration: underline !important;
}

.ct {
  z-index: 10000;
}