@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(../assets/fonts/Inter//inter-v12-latin-100.ttf) format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(../assets/fonts/Inter/inter-v12-latin-200.ttf) format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(../assets/fonts/Inter/inter-v12-latin-300.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(../assets/fonts/Inter/inter-v12-latin-400.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(../assets/fonts/Inter/inter-v12-latin-500.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(../assets/fonts/Inter/inter-v12-latin-600.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(../assets/fonts/Inter/inter-v12-latin-700.ttf) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(../assets/fonts/Inter/inter-v12-latin-800.ttf) format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(../assets/fonts/Inter/inter-v12-latin-900.ttf) format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
    url(../assets/fonts/Lato/Lato-Thin.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
    url(../assets/fonts/Lato/Lato-ThinItalic.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
    url(../assets/fonts/Lato/Lato-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
    url(../assets/fonts/Lato/Lato-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
    url(../assets/fonts/Lato/Lato-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
    url(../assets/fonts/Lato/Lato-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
    url(../assets/fonts/Lato/Lato-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
    url(../assets/fonts/Lato/Lato-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
    url(../assets/fonts/Lato/Lato-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
    url(../assets/fonts/Lato/Lato-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-Thin.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-ThinItalic.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-ExtraLight.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf) format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../assets/fonts/Poppins/Poppins-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}
