.MuiOutlinedInput-input.Mui-disabled.no-fade {
  color: #696e70 !important;
  -webkit-text-fill-color: #696e70 !important;
}

.MuiTextField-root.no-border fieldset {
  border: none !important;
}

.MuiChartsTooltip-root {
  border: 1px solid #F1F3F5 !important;
  border-radius: 8px !important;
  box-shadow: 0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12);
}

.MuiChartsTooltip-root .MuiTypography-root {
  font-size: 0.8rem !important;
  font-weight: bold !important;
  color: darkgray !important;
}

.MuiChartsAxis-root .MuiChartsAxis-line {
  stroke: darkgray !important;
}

.MuiChartsAxis-root .MuiChartsAxis-tick {
  stroke: darkgray !important;
}

.MuiChartsAxis-root .MuiChartsAxis-tickLabel {
  fill: darkgray !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-size: 1rem;
  font-weight: bold !important;
  color: black;
}

/* Dashboard notes */
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: inherit !important;
  border-width: 1px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #dfe2e6 !important;
}