.image-marker {
  max-width: 100%;
  max-height: 100%;
  margin: 0 !important;
}

.image-marker__image {
  max-width: 100%;
  max-height: 100%;
}

.image-marker-scale-up {
  -webkit-transform: scale(2.1);
  -ms-transform: scale(2.1);
  transform: scale(2.1);
}

.image-marker-scale-up-selected {
  -webkit-transform: scale(2.1);
  -ms-transform: scale(2.1);
  transform: scale(2.1);
}
